import { get } from 'lodash';
import React, { Component } from 'react';

import { getAbsoluteUrl } from '../utils';

const FACEBOOK_ICON = '/images/icons/facebook.png';
const TWITTER_ICON = '/images/icons/twitter.png';
const LINKEDIN_ICON = '/images/icons/linkedin.png';
const SKYPE_ICON = '/images/icons/skype.png';

class CompanyPage extends Component {

  renderSocialBadge(url, icon) {
    if (!url || url.match(/^.*\/none|none|/)[0]) {
      return null;
    }
    return (
      <span>
        <a target="_blank" href={url} title={url}>
          <img className="social-icon" src={icon} />
        </a>
      </span>
    );
  }

  renderHeader() {
    const { company } = this.props;
    const default_company_user = get(company, 'default_company_user') || {};
    const { avatar, contact_facebook, contact_twitter, contact_linkedin, contact_skype } = default_company_user;
    const company_name = company.brand_name || company.company_name;
    const { background_image, background_image_position } = company;
    const backgroundStyle = {
      backgroundImage:  !background_image ? `url(../../images/backgrounds/commonsku-patterns-geometric.png)` : `url('${getAbsoluteUrl(background_image)}')`,
      backgroundPosition: `0 ${background_image_position ? background_image_position : 0}px`,
      backgroundSize: '100%',
      position: 'relative',
      height: '294px',
      padding: '30px 30px 64px 30px',
      marginLeft: 0
    };
    const avatarStyle = {
      position: 'relative',
      float: 'left',
      display: 'block',
      marginRight: '30px',
      width: '200px',
      height: '200px',
      overflow: 'hidden',
      borderRadius: '5px',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.4)',
      textAlign: 'center'
    };
    const headerStyle = {
      fontSize: '36px',
      lineHeight: '42px',
      color: '#fff',
      textShadow: '1px 1px rgba(0,0,0,.5), 0 0 6px rgba(0,0,0,.6)',
      fontFamily: 'museo-slab, sans-serif'
    };
    return (
      <div className="row small-12 columns" style={backgroundStyle}>
        <div style={avatarStyle}>
          {avatar ? <img src={getAbsoluteUrl(avatar.large)} style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)' }}  alt={""}/> : company.company_type === 'SUPPLIER' ? <img src ='../../images/icons/supplier-home-icon.svg' alt =""/> : null}
        </div>
        <h1 style={headerStyle}>{company_name}</h1>
        {this.renderSocialBadge(contact_facebook, FACEBOOK_ICON)}
        {this.renderSocialBadge(contact_twitter, TWITTER_ICON)}
        {this.renderSocialBadge(contact_linkedin, LINKEDIN_ICON)}
        {this.renderSocialBadge(contact_skype, SKYPE_ICON)}
      </div>
    );
  }

  renderNav() {
    const navStyle = {
      position: 'relative',
      marginTop: '-34px',
      zIndex: 2,
      borderTop: 0,
      background: 'rgba(0,0,0,.25)',
      borderBottom: '1px solid #eaeaea',
      borderWidth: '1px 0',
      height: '34px',
      padding: '0',
      marginLeft: 0
    };
    const { company: { company_type } } = this.props;

    return (
      <div className="row small-12 columns" style={navStyle}>
        {'SUPPLIER' === company_type ? this.renderSupplierNav() : this.renderDistributorNav()}
      </div>
    );
  }

  renderSupplierNav() {
    const { page } = this.props;
    const menuItemStyle = {
      float: 'left'
    };

    const linkStyle = {
      color: '#fff',
      textShadow: '1px 1px rgba(0,0,0,.5)',
      display: 'inline-block',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      padding: '5px',
      fontSize: '14px'
    };

    const activeLinkStyle = {
      color: '#fff',
      textShadow: 'none',
      display: 'inline-block',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      padding: '5px',
      fontSize: '14px',
      fontWeight: 700,
      borderBottom: '3px solid #5ba0b4'
    };

    const { company, identity } = this.props;
    const hasCapability = capability => identity.capabilities.includes(capability);
    return (
      <ul style={{ listStyleType: 'none' }}>
        <li style={menuItemStyle}>
          <a style={'profile' === page ? activeLinkStyle : linkStyle} href={`/vendor.php?id=${company.company_id}&division_id=${company.division_id}`}>Profile</a>
        </li>
        <li style={menuItemStyle}>
          <a style={'products' === page ? activeLinkStyle : linkStyle} href={`/vendor_products.php?id=${company.company_id}&division_id=${company.division_id}`}>Products</a>
        </li>
        <li style={menuItemStyle}>
          <a style={'collections' === page ? activeLinkStyle : activeLinkStyle} href={`/vendor_collections.php?id=${company.company_id}&division_id=${company.division_id}`}>Collections</a>
        </li>
        {hasCapability('MODIFY-NETWORK-INFO') ?
        <li style={menuItemStyle}>
          <a style={linkStyle} href={`/vendor_information.php?id=${company.company_id}&division_id=${company.division_id}`}>Information</a>
        </li> : null}
      </ul>
    );
  }

  renderDistributorNav() {
    const { page } = this.props;

    const menuItemStyle = {
      float: 'left'
    };

    const linkStyle = {
      color: '#fff',
      textShadow: '1px 1px rgba(0,0,0,.5)',
      display: 'inline-block',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      padding: '5px',
      fontSize: '14px'
    };

    const activeLinkStyle = {
      color: '#fff',
      textShadow: 'none',
      display: 'inline-block',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      padding: '5px',
      fontSize: '14px',
      fontWeight: 700,
      borderBottom: '3px solid #5ba0b4'
    };

    const { company, identity } = this.props;
    const hasCapability = capability => identity.capabilities.includes(capability);
    return (
      <ul style={{ listStyleType: 'none' }}>
        <li style={menuItemStyle}>
          <a style={'profile' === page ? activeLinkStyle : linkStyle} href={`/distributor.php?id=${company.company_id}`}>Profile</a>
        </li>
        {hasCapability('MODIFY-NETWORK-INFO') ?
        <li style={menuItemStyle}>
          <a style={'information' === page ? activeLinkStyle : linkStyle} href={`/distributor_information.php?id=${company.company_id}`}>Information</a>
        </li> : null}
        {hasCapability('MODIFY-NETWORK-INFO') ?
        <li style={menuItemStyle}>
          <a style={'contacts' === page ? activeLinkStyle : linkStyle} href={`/distributor_contacts.php?id=${company.company_id}`}>Contact</a>
        </li> : null}
        {hasCapability('MODIFY-NETWORK-INFO') ?
        <li style={menuItemStyle}>
          <a style={'billing' === page ? activeLinkStyle : linkStyle} href={`/distributor_billing.php?id=${company.company_id}`}>Billing</a>
        </li> : null}
        {hasCapability('FEATURE-CRM') && hasCapability('COMPANY-TYPE-SUPPLIER') ? [
          0 == identity.association ?
            <li key="jobs" style={menuItemStyle}>
            <a style={'jobs' === page ? activeLinkStyle : linkStyle} href={`/distributor_jobs.php?id=${company.company_id}`}>Jobs</a>
          </li> : null,
          <li key="library" style={menuItemStyle}>
            <a style={'library' === page ? activeLinkStyle : linkStyle} href={`/distributor_library.php?id=${company.company_id}`}>Library</a>
          </li> ] : null}
        {hasCapability('COMPANY-TYPE-SUPPLIER') ?
        <li style={menuItemStyle}>
          <a style={'collections' === page ? activeLinkStyle : linkStyle} href={`/distributor_collections.php?id=${company.company_id}`}>Collections</a>
        </li> : null}
      </ul>
    );
  }

  render() {
    const { children } = this.props;
    const stylishChildren = React.cloneElement(children, { style: { marginLeft: 0 } });
    return (
      <div>
        {this.renderHeader()}
        {this.renderNav()}
        {stylishChildren}
      </div>
    );
  }
};

export default CompanyPage;
