import React from 'react';
import { connect } from 'react-redux';

import { createSpinnerPopup } from '../actions/popup';
import { createAddCollection } from '../actions/collection';

import CompanyPage from './CompanyPage';
import CollectionList from './CollectionList';

const CompanyCollectionsPage = ({ company, identity, onAddCollection }) => (
  <div className="main-content full-width">
    <CompanyPage company={company} identity={identity} page="collections">
      <CollectionList company_id={company.company_id} division_id={company.division_id} />
    </CompanyPage>
    {company.company_id === identity.company_id && identity.capabilities.includes('CREATE-COLLECTION') ?
      <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: '#666', zIndex: 200 }}>
        <a className="button" style={{ float: 'right', margin: '0.5rem' }} onClick={e => onAddCollection()}>Create Collection</a>
      </div> :
      null}
  </div>
);

const mapDispatchToProps = dispatch => ({
  onAddCollection: () => Promise.resolve(dispatch(createSpinnerPopup('Creating Collection...'))).then(
    () => dispatch(createAddCollection())
  )
});

export default connect(null, mapDispatchToProps)(CompanyCollectionsPage);
