import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCompany, getPopups } from '../selectors';

import { createNewFeaturePopup } from '../actions/popup';

import Header from './Header';
import MainSection from './MainSection';

import ErrorBoundary from '../components/ErrorBoundary';
import Overlay from '../components/Overlay';
import CompanyCollectionsPage from '../components/CompanyCollectionsPage';

import { oauth } from '../utils';

class CompanyApp extends Component {

  componentDidMount() {
    const { user_id, onCreateNewFeaturePopup } = this.props;

    oauth('GET', `user/${user_id}`, { should_show_popup: true }).then(({ json }) => {
      if(json.show_popup == 1) {
        onCreateNewFeaturePopup();
      }
    });
  }

  render() {
    const { popups, company, identity } = this.props;

    return (
      <ErrorBoundary>
        <Header></Header>
        <MainSection popups={popups}>
          <CompanyCollectionsPage company={company} identity={identity} />
        </MainSection>
        <Overlay popups={popups} />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    identity: state.identity,
    company: getCompany(state, { company_id: ownProps.params?.id }),
    popups: getPopups(state),
    user_id: state.identity.user_id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateNewFeaturePopup: () => {
      dispatch(createNewFeaturePopup());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyApp);
