import React from 'react';
import { BrowserRouter, Route, Routes, useLocation, useParams } from "react-router-dom";
import CompanyApp from '../containers/CompanyApp';
import NoMatch from './NoMatch';
import { getQueryParams } from '../utils';
import ErrorBoundary from './ErrorBoundary';

const AppRoute = React.memo(({ panel }) => {
  const params = useParams();
  const location = useLocation();
  const queryParams = getQueryParams({ location });

  if (panel) {
    return <CompanyApp panel={panel} params={{ ...params, ...queryParams }} location={location} />;
  }

  return <NoMatch location={location} />;
});

const CompanyRouter = ({ location }) => (
  <BrowserRouter basename={location}>
    <Routes>
      <Route
        path="/vendor_collections.php"
        element={<AppRoute panel="collections" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path={"*"}
        element={<AppRoute noMatch />}
        ErrorBoundary={ErrorBoundary}
      />
    </Routes>
  </BrowserRouter>
);

export default CompanyRouter;
